@import 'var-overrides';
@import '~react-toastify/dist/ReactToastify.css';
@import '~bootstrap/scss/bootstrap';
@import 'custom-variables';
@import 'fonts';
@import 'typography';
@import 'evaluations';

.h-vh {
    height: 100vh;
}
