.block {
    margin-bottom: 1rem;
    box-sizing: border-box;
}

.block.preview {
    border: 2px solid transparent;
    border-radius: 0.25rem;
    cursor: pointer;

    &:hover,
    &.selected {
        border-color: #bcd2db;
    }
}
